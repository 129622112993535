<template>
  <v-row class="blog-bigItem mb-5 mb-md-12">
    <v-col cols="12" md="7" xl="6" class="pb-0 pb-md-3">
      <div class="blog-bigItem_imgBox">
        <v-img
          @click="goDetail"
          cover
          class="blog-bigItem_imgBox_img pointer"
          width="100%"
          aspect-ratio="1.777"
          :src="photoUrl"
        ></v-img>
      </div>
    </v-col>
    <v-col cols="12" md="5" xl="6" class="pt-2 pt-md-3">
      <div class="blog-bigItem_textBox d-flex flex-column justify-space-start">
        <div class="mb-4 mb-md-0">
          <div class="d-flex mt-4 mt-md-0">
            <p class="blog-bigItem_textBox_date mr-3">{{ created_at }}</p>
            <p class="blog-bigItem_textBox_tag">{{ category_name }}</p>
          </div>
          <h1 class="blog-bigItem_textBox_title text-overflow-ep-2">
            {{ name }}
          </h1>
          <div v-html="content" class="blog-bigItem_textBox_content text-overflow-ep-3 text-overflow-ep-md-2 text-overflow-ep-xl-4 html-editor"></div>
        </div>
        <div class="d-flex justify-end mt-auto">
          <TypeB-btn :to="to">{{ $t("action.more") }}</TypeB-btn>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import item from "@/components/blog/item.js";
export default {
  mixins: [item],
  computed: {},
};
</script>